import React, { useState } from "react";
// import { Table } from '@mui/material';
import { Order } from "utils/sorting";
import { ReleaseNoteDto } from "./ReleaseNote";
import ReleaseNoteHead from "./ReleaseNoteHead";
import ReleaseNoteBody from "./ReleaseNoteBody";
import { Box, Table } from "@mui/material";
interface Props {
  records?: ReleaseNoteDto[];
}

const ReleaseNoteHistory = ({ records = [] }: Props) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof ReleaseNoteDto>("ReleaseNotesDate");

  const handleRequestSort = (property: keyof ReleaseNoteDto) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <ReleaseNoteHead
        {...{ order, orderBy, onRequestSort: handleRequestSort }}
      />
      <Box sx={{ overflowY: "auto", maxHeight: "230px" }}>
        <Table className="history-table" sx={{ borderBottom: "solid" }}>
          <ReleaseNoteBody {...{ order, orderBy, records }} />
        </Table>
      </Box>
    </div>
  );
};

export default React.memo(ReleaseNoteHistory);
