import {
  Typography,
} from "@mui/material";
import { styled } from "@mui/material";
import PageSection from "controls/global/page-section";
import { SignatureDefaultDialogProvider } from "pages/signatures/providers/signature-defaults/SignatureDefaultDialogProvider";
import React, {
  useEffect,
} from "react";
import { gaps } from "theme/defaultStyle";
import DefaultSignaturesIcon from "theme/icons/DefaultSignaturesIcon";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";
import { useSignatures } from "utils/context/SignaturesContext";
import SignatureDefaultDialog from "../default-dialog";
import AddDefaultLink from "./AddDefaultLink";
import SignatureDefaultsTable from "./SignatureDefaultsTable";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";

type Props = {
  titleRef?: React.MutableRefObject<any>;
};

const SUB_HEADING_TEXT = `Set a default signature for an agency, state or agency location at a
user or agency-wide level. Default signatures set at an agency-wide
level will be set for all users that have access to that agency.`;

const SectionContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const SignatureDefaults = ({ titleRef }: Props) => {
  const [, { getDefaults }] = useSignatureDefaults();
  const [{ signatureModified },] = useSignatures();
  const [{ selectedAgency: globalAccessAgency }] = useGlobalAccess();

  useEffect(() => {
    getDefaults(globalAccessAgency[0]?.CompanyID);
  }, [getDefaults, signatureModified,globalAccessAgency]);

  return (
    <PageSection
      icon={<DefaultSignaturesIcon />}
      title="Default Signatures"
      titleRef={titleRef}
      isError={false}
      contentAlignment="below-header"
    >
      <SectionContainer>
        <Typography className="subheading">
          {SUB_HEADING_TEXT}
        </Typography>
        <SignatureDefaultDialogProvider>
          <SignatureDefaultsTable />
          <AddDefaultLink />
          <SignatureDefaultDialog />
        </SignatureDefaultDialogProvider>
      </SectionContainer>
    </PageSection >
  );
};

export default SignatureDefaults;
