import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  Delete,
  Edit,
} from "@mui/icons-material";
import { styled } from "@mui/material";
import ConfirmationDialog from "controls/global/dialogs/confirmation-dialog";
import TooltipCell from "controls/global/stewart-table/TooltipCell";
import { SignatureDefaultGroup } from "entities/UIModel/SignatureDefaultGroup";
import { useSignatureDefaultDialogProvider } from "pages/signatures/providers/signature-defaults/SignatureDefaultDialogProvider";
import React, {
  useEffect,
  useState,
} from "react";
import {
  borderRadius,
  colors,
  defaultStyle,
  fontSize,
  fontWeight,
  iconSize,
  letterSpacing,
  lineHeight,
  padding,
} from "theme/defaultStyle";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";
import { useSignatures } from "utils/context/SignaturesContext";
import SignatureDefaultsTableHeader from "./SignatureDefaultsTableHeader";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { PERMISSIONS } from "utils/data/enum";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";

interface StyledTableCellProps {
  text: string;
}

const NO_SIGNATURES_MESSAGE =
  "You must add a signature in Manage Signatures before setting defaults.";
const NO_DEFAULTS_MESSAGE =
  "There are currently no default signatures configured.";
const DELETE_USER_ONLY_MESSAGE =
  "Are you sure you want to delete this defaulted signature?";
const DELETE_AGENCY_WIDE_MESSAGE =
  "This default affects one or more users.  Are you sure you want to delete this defaulted signature?";

const StyledTableBody = styled(TableBody)({
  "& tr.selected-row": {
    backgroundColor: colors.grey10,
  },
  "& tr td": {
    borderBottom: defaultStyle.table.border,
    borderTop: "none",
  },
  "& tr:first-child td": {
    borderTop: defaultStyle.table.border,
  },
  "& tr:last-child td": {
    borderBottom: defaultStyle.table.border,
  },
  "& tr td:first-child": {
    borderLeft: defaultStyle.table.border,
  },
  "& tr td:last-child": {
    borderRight: defaultStyle.table.border,
  },
  "& tr:first-child td:first-child": {
    borderTopLeftRadius: borderRadius.small,
  },
  "& tr:first-child td:last-child": {
    borderTopRightRadius: borderRadius.small,
  },
  "& tr:last-child td:first-child": {
    borderBottomLeftRadius: borderRadius.small,
  },
  "& tr:last-child td:last-child": {
    borderBottomRightRadius: borderRadius.small,
  },
});

const EmptySignatures = styled(Box)({
  color: colors.grey11,
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  padding: `${padding.small2} ${padding.zero}`,
});

const TextTableCell = ({ text }: StyledTableCellProps) => {
  const StyledCell = styled(TableCell)({
    fontSize: fontSize.large,
    fontWeight: fontWeight.normal1,
    letterSpacing: letterSpacing.medium1,
    lineHeight: lineHeight.large,
    maxWidth: 0,
    overflow: "hidden",
    paddingBottom: padding.medium2,
    paddingLeft: padding.medium1,
    paddingRight: padding.medium1,
    paddingTop: padding.medium2,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  });

  return (
    <StyledCell>
      <TooltipCell title={text} />
    </StyledCell>
  );
};

const ButtonTableCell = styled(TableCell)({
  maxWidth: "66px",
  padding: padding.zero,
  textAlign: "center",
  "& .MuiSvgIcon-root": {
    color: colors.blue01,
    cursor: "pointer",
    fontSize: iconSize.medium,
  },
});

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: 0,
});

const StyledIconButton = styled(IconButton)({
  "&.Mui-disabled": {
      opacity: 0.5,
    },
  });

function SignatureDefaultsContent() {
  const [selectedRow, setSelectedRow] = useState<SignatureDefaultGroup | undefined>(undefined);
  const [message, setMessage] = useState<string | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [disableConfirmationDialogYes, setDisableConfirmationDialogYes] = useState(false);
  const [{ signatures }, { getSignatures }] = useSignatures();
  const {
    isOpen,
    showEdit,
  } = useSignatureDefaultDialogProvider();
  const [{ defaults, userOnlyLevelCode }, { deleteDefault, getDefaults, editSetup }] = useSignatureDefaults();
  const [{ selectedAgency: globalAccessAgency }] = useGlobalAccess();

  const handleEdit = async (signatureDefaultGroup: SignatureDefaultGroup) => {
    setSelectedRow(signatureDefaultGroup);
    editSetup(signatureDefaultGroup);
    showEdit();
  };

  const handleRequestDelete = (signatureDefaultGroup: SignatureDefaultGroup) => {
    const msg = signatureDefaultGroup.signatureLevelTypeCode === userOnlyLevelCode
      ? DELETE_USER_ONLY_MESSAGE
      : DELETE_AGENCY_WIDE_MESSAGE;
    setDisableConfirmationDialogYes(false);
    setSelectedRow(signatureDefaultGroup);
    setDeleteMessage(msg);
    setShowDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    setDisableConfirmationDialogYes(true);
    if (selectedRow) {
      await deleteDefault(selectedRow?.existingUserAgencySignatureDefaultIDs);
      getSignatures();
      getDefaults(globalAccessAgency[0]?.CompanyID);
    }
    setSelectedRow(undefined);
    setShowDeleteDialog(false);
  };

  const handleCancelDelete = () => {
    setSelectedRow(undefined);
    setShowDeleteDialog(false);
  };

  const getRowClassNames = (key: string) => {
    return selectedRow?.key === key ? "selected-row" : "";
  };

  useEffect(() => {
    if (!signatures || signatures.length < 1) {
      setMessage(NO_SIGNATURES_MESSAGE);
      return;
    }
    if (!defaults || defaults.length < 1) {
      setMessage(NO_DEFAULTS_MESSAGE);
      return;
    }
    setMessage(null);
  }, [signatures, defaults]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedRow(undefined);
    }
  }, [isOpen]);
  const [{ profileSettings }] = useProfileSettingsCache();

  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];

  const hasEditSignatureAccess = permissionCodes?.includes(PERMISSIONS.SIGNATURES_EDIT);
  const hasDeleteSignatureAccess = permissionCodes?.includes(PERMISSIONS.SIGNATURES_DELETE);

  return (
    <>
      {!message ? (
        <StyledTable>
          <SignatureDefaultsTableHeader />
          <StyledTableBody>
            {defaults?.map((record) => (
              <TableRow
                key={record.key}
                className={getRowClassNames(record.key)}
              >
                <TextTableCell text={record.agencyName} />
                <TextTableCell text={record.state} />
                <TextTableCell text={record.locationDisplayName ?? "All Locations"} />
                <TextTableCell text={record.signatureLevelTypeName} />
                <TextTableCell text={record.signatureImageName} />
                <ButtonTableCell>
                  <StyledIconButton
                    onClick={() => handleEdit(record)}
                    disabled={!hasEditSignatureAccess}
                  >
                    <Edit />
                  </StyledIconButton>
                </ButtonTableCell>
                <ButtonTableCell>
                  <StyledIconButton
                    onClick={() => handleRequestDelete(record)}
                    disabled={!hasDeleteSignatureAccess}
                  >
                    <Delete />
                  </StyledIconButton>
                </ButtonTableCell>
              </TableRow>
            ))}
          </StyledTableBody>
        </StyledTable>
      ) : (
        <EmptySignatures>{message}</EmptySignatures>
      )}
      {showDeleteDialog && (
        <ConfirmationDialog
          type={"ERROR"}
          confirmationMessage={deleteMessage}
          isOpen={showDeleteDialog}
          onYes={handleConfirmDelete}
          onNo={handleCancelDelete}
          disableYes={disableConfirmationDialogYes}
        />
      )}
    </>
  );
}

export default SignatureDefaultsContent;
