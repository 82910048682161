import {
  Typography,
} from "@mui/material";
import { styled } from "@mui/material";
import PageSection from "controls/global/page-section";
import React, {
  useEffect,
} from "react";
import { gaps } from "theme/defaultStyle";
import ManageSignaturesIcon from "theme/icons/ManageSignaturesIcon";
import { useLookup } from "utils/context/LookupContext";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";
import { useSignatures } from "utils/context/SignaturesContext";
import SignatureSectionProvider from "../../providers/manage-signature";
import SignatureDialog from "../signature-dialog";
import AddSignatureLink from "./AddSignatureLink";
import SignaturesTable from "./SignaturesTable";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";

type Props = {
  titleRef?: React.MutableRefObject<any>;
};

const SUB_HEADING_TEXT = `Add or edit a signature below. These signatures will be available to
other users that have access to the same agencies and/or agency
locations.`;

const SectionContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: gaps.large1,
});

const ManageSignatures = ({ titleRef }: Props) => {
  const [{ signatures }, { getSignatures }] = useSignatures();
  const [, { setAgencyWideLevelCode, setUserOnlyLevelCode }] = useSignatureDefaults();
  const [ { selectedAgency },] = useGlobalAccess();
  const [
    { signatureLevelTypes },
    {
      getSignatureImageFontTypes,
      getSignatureImageFormatTypes,
      getSignatureImageSourceTypes,
      getSignatureLevelTypes
    }] = useLookup();

  useEffect(() => {
    getSignatures(selectedAgency[0]?.CompanyID);
    getSignatureImageFontTypes();
    getSignatureImageFormatTypes();
    getSignatureImageSourceTypes();
    getSignatureLevelTypes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgency]);

  useEffect(() => {
    const agencyCode = signatureLevelTypes.find(t => t.isAgencyWide)?.code;
    if (agencyCode) {
      setAgencyWideLevelCode(agencyCode);
    }
    const userCode = signatureLevelTypes.find(t => t.isUserOnly)?.code;
    if (userCode) {
      setUserOnlyLevelCode(userCode);
    }

  }, [signatureLevelTypes,selectedAgency]);

  return (
    <PageSection
      icon={<ManageSignaturesIcon />}
      title="Manage Signatures"
      titleRef={titleRef}
      isError={false}
      contentAlignment="below-header"
    >
      <SectionContainer>
        <Typography className="subheading">
          {SUB_HEADING_TEXT}
        </Typography>
        <SignatureSectionProvider>
          <SignaturesTable records={signatures}></SignaturesTable>
          <AddSignatureLink />
          <SignatureDialog />
        </SignatureSectionProvider>
      </SectionContainer>
    </PageSection>
  );
};

export default ManageSignatures;
