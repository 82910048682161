import { styled } from "@mui/material";
import SelectField from "controls/global/select-field";
import { Signature } from "entities/UIModel/Signature";
import React, {
  useEffect,
} from "react";
import {
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  colors,
  fontSize,
  fontWeight,
  margin,
  padding,
} from "theme/defaultStyle";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useSignatureDefaults } from "utils/context/SignatureDefaultsContext";
import { useSignatures } from "utils/context/SignaturesContext";

const FormField = styled("div")({
  marginTop: margin.xlarge1,
});

const FieldTitle = styled("div")({
  fontFamily: "Roboto, sans-serif",
  fontSize: fontSize.xlarge0,
  fontWeight: fontWeight.normal1,
  padding: `${padding.zero} ${padding.small}`,
});

const InnerWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const NameFieldWrapper = styled("div")({
  width: "210px",
});

const SignatureImageWrapper = styled("div")({
  width: "calc(100% - 230px)",
});

const ImageInnerWrraper = styled("div")({
  alignItems: "center",
  display: "flex",
  height: "55px",
  justifyContent: "center",
});

const SignatureImage = styled("img")({
  maxWidth: "125px",
  width: "100%",
});

const FieldLabel = styled("div")({
  color: colors.grey08,
  fontSize: fontSize.base,
  fontWeight: fontWeight.normal2,
  marginBottom: margin.small1,
  paddingLeft: padding.small1,
});

const StyledSelectField = styled(SelectField)({
  "& .MuiInputLabel-shrink": {
    display: "none"
  },
  "& legend": {
    display: "none"
  }
});

const FIELD_NAME = "signatureImage";

const SignatureInfo = () => {
  const [{ signatures }] = useSignatures();
  const { setValue } = useFormContext();
  const [ { selectedAgency },] = useGlobalAccess();
  const [, { getAgencyLocationsByImage }] = useSignatureDefaults();
  const [imageField] = useWatch({
    name: ["signatureImage"],
  });

  const mappedSignatures =
    signatures?.map((signature: Signature) => ({
      text: signature.signatureImageName,
      value: signature.signatureImageID,
    })) || [];

  const getSignatureImageSource = () => {
    const dataSoure = signatures?.find(
      (signature: Signature) =>
        signature.signatureImageID === Number(imageField?.value)
    )?.signatureImageData;
    return dataSoure;
  };

  useEffect(() => {
    if (imageField?.value) {
      getAgencyLocationsByImage(imageField.value,selectedAgency[0]?.CompanyID);
    }
  }, [imageField]);

  useEffect(() => {
    if (mappedSignatures.length === 1) {
      setValue(FIELD_NAME, {
        text: mappedSignatures[0].text,
        value: mappedSignatures[0].value,
      });
    }
  }, [mappedSignatures]);

  return (
    <>
      <FieldTitle>Choose a signature.</FieldTitle>
      <FormField>
        <InnerWrapper>
          <NameFieldWrapper>
            <FieldLabel>NAME</FieldLabel>
            <StyledSelectField
              label="Choose Signature"
              name={FIELD_NAME}
              options={mappedSignatures}
              optionsValueField="value"
              optionsTextField="text"
              dataValueField="value"
              dataTextField="text"
              disabled={mappedSignatures.length === 0}
            />
          </NameFieldWrapper>
          <SignatureImageWrapper>
            <FieldLabel>IMAGE</FieldLabel>
            <ImageInnerWrraper>
              <SignatureImage
                src={getSignatureImageSource()}
                alt={imageField.text}
              />
            </ImageInnerWrraper>
          </SignatureImageWrapper>
        </InnerWrapper>
      </FormField>
    </>
  );
};

export default SignatureInfo;
