// import { Close } from "@mui/icons-material";
import { SignatureConfigurationNode } from "entities/ApiModel/SignatureConfigurationNode";
import { useConfigurationProvider } from "pages/signatures/providers/manage-signature/ConfigurationProvider";
import {
  useSignatureDispatchProvider,
  useSignatureProvider,
} from "pages/signatures/providers/manage-signature/SignatureProvider";
import { useWizardProvider } from "pages/signatures/providers/manage-signature/WizardProvider";
import React, {
  useEffect,
  useState,
} from "react";
import { useSignatures } from "utils/context/SignaturesContext";
import { selectAllLocationNodes } from "utils/signature/treeView";
import {
  StewartDialog,
  StewartDialogActions,
  StewartDialogContent,
  StewartDialogTitle,
} from "controls/global/dialogs/stewart-dialog/StewartDialog";
import NextButton from "./buttons/NextButton";
import SaveButton from "./buttons/SaveButton";
import BackButton from "./buttons/BackButton";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import DialogTitleCloseButton from "controls/global/dialog-close-btn/DialogTitleCloseButton";

const SignatureDialog = () => {
  const [title, setTitle] = useState("");
  const [{ cachedSignature }, { getSignatureConfiguration }] = useSignatures();
  const { state: { mode, open }, } = useSignatureProvider();
  const { dispatch: signatureDispatch } = useSignatureDispatchProvider();
  const [{ selectedAgency: globalAccessAgency }] = useGlobalAccess();

  const {
    state: { activeStep },
    dispatch: wizardDispatch,
  } = useWizardProvider();
  const {
    dispatch: configurationDispatch,
  } = useConfigurationProvider();

  const reset = () => {
    wizardDispatch({ type: "RESET" });
    configurationDispatch({ type: "RESET" });
    signatureDispatch({ type: "CLOSE" });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      reset();
      event.preventDefault();
    }
  };

  useEffect(() => {
    const initTree = async () => {
      const data: SignatureConfigurationNode | undefined =
        mode === "ADD"
          ? await getSignatureConfiguration(globalAccessAgency[0]?.CompanyID)
          : cachedSignature?.signatureConfiguration;

      if (mode === "ADD" && data) {
        selectAllLocationNodes(data);
        data.Expanded = false;
      }

      configurationDispatch({
        type: "INIT",
        data,
      });
    };

    if (activeStep.key === "CONFIG") {
      initTree();
    }
  }, [
    cachedSignature?.signatureConfiguration,
    cachedSignature?.signatureImageID,
    configurationDispatch,
    getSignatureConfiguration,
    mode,
    activeStep.key,
  ]);

  useEffect(() => {
    switch (mode) {
      case "ADD":
        setTitle("Add Signature");
        break;
      case "EDIT":
        setTitle("Edit Signature");
        break;
      default:
        break;
    }
  }, [mode]);

  return (
    <StewartDialog open={open} maxWidth="lg" onKeyDown={handleKeyDown}>
      <StewartDialogTitle type={"INFO"}>
        {title}
        <DialogTitleCloseButton onClick={reset} />
      </StewartDialogTitle>
      <StewartDialogContent>{activeStep.Component}</StewartDialogContent>
      <StewartDialogActions>
        <>
          {activeStep.key !== "MENU" && (
            <BackButton activeStep={activeStep.key} />
          )}
          <NextButton activeStep={activeStep.key} />
          <SaveButton resetProviders={reset} />
        </>
      </StewartDialogActions>
    </StewartDialog>
  );
};

export default SignatureDialog;
