import React, { useRef } from 'react';
import {
  IconButton,
  styled,
  TableCell,
  TableCellProps,
  TableRow,
} from '@mui/material';
import {
  colors,
  fontSize,
  fontWeight,
} from 'theme/defaultStyle';
import { ReleaseNoteDto } from './ReleaseNote';
import { useProfileSettingsCache } from 'utils/context/ProfileSettingsContext';
import ViewIcon from 'theme/icons/ViewIcon';
import { axiosSecuredInstance } from 'configurations/axiosConfig';
import { HELP_CENTER_RELEASE_NOTE_API_PATH } from 'pages/help-center/utils';
import { getBase64DataType } from 'utils/shared';
import { DownloadFileType } from 'utils/data/enum';
import EmbedViewer, {
  EmbedViewerRefType,
} from 'controls/global/embed-viewer/EmbedViewer';
declare module "@mui/material/TableCell" {
  interface TableCellProps {
    minWidth?: string;
    maxWidth?: string;
  }
}

interface Props {
  record?: ReleaseNoteDto;
}

const StyledNoRow = styled(TableRow)({
  backgroundColor: colors.white,
  verticalAlign: "top",
});

const StyledTableCell = styled(TableCell)<TableCellProps>(({ maxWidth }) => ({
  color: colors.grey08,
  fontSize: fontSize.large,
  fontWeight: fontWeight.normal1,
  maxWidth: maxWidth,
  whiteSpace: "pre-line",
  justifyContent: "end",
  minWidth: "450px",
  height: "36px",
}));

const ReleaseNoteBodyRow = ({ record }: Props) => {
  const [{ profileSettings }] = useProfileSettingsCache();
  const showEmbedViewerRef = useRef<EmbedViewerRefType>(null);
  if (!record)
    return (
      <StyledNoRow>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </StyledNoRow>
    );

  const { DocFileName, ReleaseNoteId, Name } = record;

  async function getReleaseNoteItem(releaseNoteId: string): Promise<ReleaseNoteDto> {
    const { data } = await axiosSecuredInstance.get(`${HELP_CENTER_RELEASE_NOTE_API_PATH}/${releaseNoteId}`);
    return data;
  }

  function showContent(quickReferenceCardId: string, fileName: string) {
    getReleaseNoteItem(quickReferenceCardId).then(({ FileBase64 }: ReleaseNoteDto) => {
      showEmbedViewerRef.current?.open(
        fileName,
        `data:${getBase64DataType(DownloadFileType.Pdf)};base64,${FileBase64}`,
      );
    });
  }

  return (
    <TableRow key={ReleaseNoteId}>
      {profileSettings?.isStaffUser === 1 && (
        <>
          <StyledTableCell>{Name}</StyledTableCell>
          <StyledTableCell sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              onClick={() => {
                showContent(ReleaseNoteId, DocFileName);
              }}
            >
              <ViewIcon />
            </IconButton>

            <EmbedViewer ref={showEmbedViewerRef} />
          </StyledTableCell>
        </>
      )}
    </TableRow>
  );
};

export default ReleaseNoteBodyRow;
