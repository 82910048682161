export const fontFamily = {
  primary: "Roboto",
  inter: "Inter",
};

export const fontSize = {
  base: "14px",
  xxsmall: "8px",
  xsmall: "10px",
  small: "12px",
  small1: "13px",
  medium: "14px",
  large: "16px",
  xlarge: "18px",
  xlarge0: "20px",
  xlarge1: "22px",
  xlarge2: "24px",
  xlarge2a: "25px",
  xlarge3: "28px",
  xlarge4: "30px",
  xlarge5: "34px",
  xlarge6: "50px",
};

export const fontWeight = {
  light1: 100,
  light2: 200,
  light3: 300,
  normal1: 400,
  normal2: 500,
  bold1: 600,
  bold2: 700,
};

export const letterSpacing = {
  medium1: "0.15px",
  medium2: "0.17px",
};

export const lineHeight = {
  zero: "0px",
  large: "24px",
  xlarge: "32px",
  xxlarge: "58px"
};

export const margin = {
  zero: 0,
  xxsmall1: "1px",
  xxsmall2: "2px", //xsmall0
  xsmall: "4px",
  xsmall1: "5px",
  small: "6px",
  small_5: "7px",
  small1: "8px",
  small1_5: "9px",
  small2: "10px",
  medium: "12px",
  medium2: "15px",
  large: "16px",
  large1: "18px",
  xlarge: "20px",
  xlarge1: "24px",
  xlarge2: "30px",
  xlarge3: "32px",
  xlarge35: "35px",
  xlarge40: "40px",
  xlarge50: "50px",
};

export const padding = {
  zero: "0px",
  xsmall: "2px",
  xsmall1: "4px",
  xsmall2: "5px",
  small: "6px",
  small0: "7px",
  small1: "8px",
  small1_5: "9px",
  small2: "10px",
  medium1: "12px",
  medium2: "14px",
  large1: "16px",
  large2: "18px",
  xlarge: "20px",
  xlarge1: "24px",
  xlarge2: "30px",
  xlarge21: "32px",
  xlarge22: "40px",
  xlarge3: "50px",
  xxlarge1: "64px",
};

export const borderRadius = {
  zero: "0px",
  xsmall: "2px",
  xsmall1: "3px",
  small: "4px",
  medium: "6px",
  large: "8px",
  xlarge: "12px",
  xlarge1: "16px",
};

export const width = {
  xsmall: "200px",
  xsmall1: "300px",
  small: "400px",
  medium: "600px",
  large: "800px",
  xlarge: "1200px",
  xlarge1: "1600px",
};
export const height = {
  xsmall: "250px",
  xsmall1: "300px",
  small: "400px",
  medium: "600px",
  large: "800px",
  xlarge: "1200px",
  xlarge1: "1600px",
};

export const borderSize = {
  zero: "0px",
  xsmall: "1px",
  small: "2px",
  medium: "3px",
  large: "4px",
  xlarge: "5px",
  xxxlarge: "16px",
};

export const iconSize = {
  xxsmall: "9px",
  small1: "16px",
  small2: "18px",
  small3: "20px",
  medium: "24px",
  medium2: "28px",
  large1: "32px",
  large2: "38px",
  large3: "40px",
  xlarge: "72px",
};

export const colors = {
  // --- Blue ---
  blue01: "#0075AA",
  blue02: "#03567B",
  blue03: "#004D82",
  blue04: "#558FAA",
  blue05: "#015E88",
  blue06: "#01415F",
  blue07: "#B2D5E5",
  blue08: "#E6F1F7",
  blue09: "#659AB1",
  blue10: "#1976D2",
  blue11: "#92C4DB",
  blue12: "#71A7D5",
  blue13: "#E9F8FF",
  blueTransparent01: "rgba(25, 118, 210, 0.04)",
  blueDisabled: "#006F9E",
  // --- Grey ---
  grey01: "#e5e5e5",
  grey02: "#f5f4f4",
  grey03: "#f2f2f2",
  grey04: "#e8e5e5",
  grey05: "#c7cdd5",
  grey06: "#ADADAD",
  grey07: "#626262",
  grey08: "#212121",
  grey09: "#BDBDBD",
  grey10: "#F5F5F5",
  grey11: "#7F7F7F",
  grey12: "#EBEBEB",
  grey13: "#797979",
  grey14: "#c4c4c4",
  grey15: "#949494",
  grey16: "#8b8b8b",
  grey17: "#e0e0e0",
  grey18: "#A6A6A6",
  grey19: "#D2D9DB",
  grey20: "#00000099",
  grey21: "#F9F9F9",
  grey22: "#666666",
  grey23: "#EDF2F2",
  grey24: "#3C494C",
  grey25: "#808080",  // color: grey
  // --- Marron ---
  maroon01: "#9e2339",
  maroon02: "#9E233A",
  maroon03: "#620000",
  maroon04: "#3A0000",
  // --- Red ---
  red01: "#E00000",
  red02: "#DC3545",
  redGradient: "linear-gradient(180deg, #9E2339 0%, #620000 100%)",
  // --- Green ---
  green: "#00875A",
  // --- Black ---
  black: "#000000",
    // --- White ---
  white: "#ffffff",
  whiteTransparent01: "rgba(255, 255, 255, 0.75)",
  // --- Yellow ---
  yellow: "#FFC400",
  // --- Orange --
  orange: "#ff991f",
};

export const gaps = {
  zero: "0",
  xsmall1: "3px",
  xsmall2: "4px",
  xsmall2_5: "6px",
  xsmall3: "7px",
  small1: "8px",
  small2: "10px",
  small3: "12px",
  medium1: "16px",
  medium2: "20px",
  large1: "24px",
  large2: "30px",
  large3: "32px",
  large4: "35px",
};

export const gradients = {
  // --- Blue ---
  blueGradient01: "linear-gradient(#189AD4, #0075AA)", // Action Blue Gradient
  blueGradient02: "linear-gradient(#6AB3D4, #558FAA)", // Action Blue Gradient Disabled
  blueGradient03: "linear-gradient(#0075AA, #004D82)", // Action Blue Gradient Dark
  blueGradient04: "linear-gradient(#558FAA, #416882)", // Action Blue Gradient Dark Disabled
  blueGradient05: "linear-gradient(#004D82, #01415F)", // Action Blue Gradient Darker
  blueGradient06: "linear-gradient(#416882, #2F505F)", // Action Blue Gradient Darker Disabled
  blueGradient07: "linear-gradient(#189AD5, #0075AA)",
  blueGradient08: "linear-gradient(180deg, #5994C6 0%, #006091 100%)",
  blueGradient09: "var(--Gradient, linear-gradient(180deg, #0075AA 0%, #004D82 100%))",
  // --- Grey ---
  greyGradient01: "linear-gradient(#7F7F7F, #555555)",
  // --- Maroon ---
  maroonGradient01: `linear-gradient(${colors.maroon01}, ${colors.maroon03})`,
  maroonGradient02: `linear-gradient(${colors.maroon03}, ${colors.maroon03})`,
  maroonGradient03: `linear-gradient(${colors.maroon04}, ${colors.black})`,
  // --- Red ---
  redGradient01: "linear-gradient(#E00000, #860000)",
  redGradient02: "linear-gradient(180deg, #B3261E 0%, #8C1D18 100%)",
  redGradient03: "-webkit-linear-gradient(90deg, #620000 30%, #9e2339 70%)",
  // --- Green ---
  greenGradient01: "linear-gradient(#00875A, #006E49)",
  // --- Yellow ---
  yellowGradient01: "linear-gradient(#FFC400, #FFAB00)",
  yellowGradient02: "linear-gradient(180deg, #FFC400 0%, #FF9400 100%)",
};

export const boxShadows = {
  boxShadow01:
    "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
  boxShadow02:
    "0px 2px 6px rgba(0, 0, 0, 0.25)",
  boxShadow03:
    "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
  boxShadow04: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  boxShadow05: "0px 4px 6px rgb(0 0 0 / 25%)",
  boxShadow06: "0px 2px 6px rgba(0, 0, 0, 0.25)"
};

export const filters = {
  filter01: "drop-shadow(0px 2px 6px #C2D8E3)",
};

export const zIndex = {
  negativeMax: -2147483648,
  negativeMin: -1,
  zero: 0,
  positiveMin: 1,
  low1: 100,
  high0: 1000,
  high1: 2000,
  high2: 3000,
  high3: 4000,
  positiveMax: 2147483647,
};

export const titleIconOverflowDistance = "13px";
export const scrollBar = {
  borderRadius: "4px",
  borderRadius1: "9px",
  height: "4px",
  width: "4px",
  width1: "9px",
}

export const defaultStyle = {
  section: {
    borderRadius: borderRadius.xlarge,
    backgroundColor: colors.white,
    boxShadow: boxShadows.boxShadow02,
    outer: {
      padding: padding.xlarge1,
    },
    inner: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  label: {
    outer: {
      fontSize: fontSize.medium,
      paddingLeft: padding.large1,
      color: colors.grey07,
    },
    inner: {
      fontSize: fontSize.xlarge,
      paddingLeft: padding.medium1,
      color: colors.grey06,
      paddingRight: padding.medium1,
    },
  },
  control: {
    fontSize: fontSize.xlarge,
    borderRadius: borderRadius.small,
    height: "40px", // input height 26 = 40 (control height) - 6(padding top) + - (padding bottom) - 1 (border top) - 1 (border bottom)
    borderSize: borderSize.xsmall,
    paddingTop: padding.small,
    paddingBottom: padding.small,
    paddingLeft: padding.medium1,
    paddingRight: padding.medium1,
    backgroundColor: colors.white,
    borderColor: colors.grey09,
    borderHoverColor: colors.blue01,
    borderFocusColor: colors.blue01,
    errorColor: colors.red02,
    disabled: {
      backgroundColor: colors.grey10,
      color: colors.grey11,
      borderColor: colors.grey11,
    },
  },
  // use for dropdownlist box
  paper: {
    //borderColor: colors.white,
    maxHeight: "300px",
    borderWidth: borderSize.medium,
    borderColor: "primary.main",
  },
  button: {
    height: "35px",
    borderRadius: borderRadius.small,
    fontSize: fontSize.base,
    paddingLeft: padding.large1,
    paddingRight: padding.large1,
    outlinePrimary: {
      color: colors.white,
      background: gradients.blueGradient03,
      boxShadow: boxShadows.boxShadow01,
      backgroundColor: colors.blue01,
      borderColor: colors.grey05,
      focus: {
        color: colors.white,
        backgroundColor: colors.blue03,
        borderColor: colors.grey05,
      },
      hover: {
        background: gradients.blueGradient05,
        borderColor: colors.grey05,
        boxShadow: "none",
        color: colors.white,
      },
    },
    outlineSecondary: {
      backgroundColor: colors.white,
      borderColor: colors.blue01,
      boxShadow: boxShadows.boxShadow01,
      color: colors.blue01,
      focus: {
        color: colors.blue03,
        backgroundColor: colors.white,
        borderColor: colors.blue03,
      },
      hover: {
        color: colors.blue03,
        backgroundColor: colors.white,
        borderColor: colors.blue03,
      },
      disabled: {
        backgroundColor: colors.grey10,
        borderColor: colors.blue09,
        color: colors.blue09,
      }
    },
  },
  checkbox: {
    borderRadius: borderRadius.xsmall,
    borderColor: colors.grey11,
  },
  radioButton: {
    borderColor: colors.grey11,
  },
  select: {
    border: `${borderSize.xsmall} solid ${colors.grey14}`,
  },
  table: {
    border: `${borderSize.xsmall} solid ${colors.grey09}`,
  },
  icon: {
    formSection: {
      width: iconSize.medium,
      height: iconSize.medium,
      gradient1: colors.maroon01,
      gradient2: colors.maroon03,
    },
  },

};
