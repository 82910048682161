import { AuthenticatedTemplate } from "@azure/msal-react";
import Save from "@mui/icons-material/Save";
import {
  AppBar,
  Box,
  Button,
  Link as MuiLink,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  styled,
} from "@mui/material/styles";
import clsx from "clsx";
import {
  MenuDefinition,
  getUrlByMenuId,
  menuDefinitions,
  menuIds,
  menuNames,
  menuType,
} from "pages/home/menu";
import React, { useEffect } from "react";
import {
  Link,
  useLocation,
} from "react-router-dom";
import theme from "theme/default";
import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gaps,
  gradients,
  iconSize,
  letterSpacing,
  margin,
  padding,
  zIndex,
} from "theme/defaultStyle";
import StewartLogo from "theme/images/stewart-connect-logo-white.svg";
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useFiles } from "utils/context/FilesContext";
import useNavRedirect from "utils/custom-hooks/useNavRedirect";
import {
  isCreateFilePage,
  isHomePage,
  isProfileSettingsPage,
  isHelpCenterPage,
} from "utils/routes/pathValidator";
import EditProfileLink from "./EditProfileLink";
import MyAppsLink from "./MyAppsLink";
import MessageBanner from "./MessageBanner";
import PaymentsHeaderDropDown from "./PaymentsHeaderDropDown";
import GlobalAccessAgency from "./GlobalAccessAgency";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";
import { useProfileSettingsCache } from "utils/context/ProfileSettingsContext";
import { useFilePolicyUploadStates } from "utils/context/ImageArchiveContext";
import {
  PERMISSIONS,
} from "utils/data/enum";

type HeaderProps = {
  isValidLogin: boolean;
  saveFileRef?: any;
  saveFileChangeRef?: any;
  isDisabled?: boolean;
};

type NavProps = {
  isValidLogin: boolean;
  saveFileRef?: any;
  saveFileChangeRef?: any;
  isDisabled?: boolean;
};

type TopNavBarProps = {
  saveFileRef?: any;
  saveFileChangeRef?: any;
  onNavigate: (url: string) => void;
};

const linkButtonHeightMin = "30px";
const linkButtonHeightMax = "122px";

const SaveIcon = styled(Save)((props) => ({
  [theme.breakpoints.up("lg")]: {
    marginRight: margin.xsmall,
  },
}));

const CustomAppBar = styled(AppBar)({
  backgroundColor: "transparent",
  boxShadow: "none",
  position: "initial",
  zIndex: zIndex.high3,
  // width: "100%" // undo Henry's changed due to it causes navigation icons are not aligned correctly
});

const StyledToolbar = styled(Toolbar)((props) => ({
  alignItems: "center",
  background: gradients.blueGradient08,
  borderRadius: `0px 0px ${borderRadius.medium} ${borderRadius.medium}`,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  fontSize: `${fontSize.base} !important`,
  fontWeight: fontWeight.normal1,
  gap: gaps.xsmall2,
  height: "75px",
  justifyContent: "center",
  // width: "100%", // undo Henry's changed due to it causes navigation icons are not aligned correctly
  padding: `${padding.small1} ${padding.xlarge1}`,
  "& > :first-child ": {
    marginRight: "auto",
  },
  "& > :last-child ": {
    marginLeft: "auto",
  },  

  [theme.breakpoints.between("sm", 801)]: {
    // NOTE: A very specific breakpoint is needed to get this to break properly to look correct.
    gap: gaps.medium2,
  },
  [theme.breakpoints.down("md")]: {
    height: "129px",
    justifyContent: "center",
  },
}));

const StyledLogo = styled(MuiLink)({
  backgroundImage: `url(${StewartLogo}) !important`,
  backgroundRepeat: "no-repeat",
  flexShrink: 0,
  height: "35px",
  width: "160px",
  "&:hover": {
    cursor: "pointer",
  },
});

const NavItemsContainer = styled(Box)((props) => ({
  "div.MuiBox-root": {
    margin: "0px 19px",
    width: "auto",
    "& a div.MuiBox-root": {
      margin: "0px 0px"
    }
  },
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: gaps.small1,
  justifyContent: "center",
  padding: padding.zero,
  [theme.breakpoints.down("md")]: {
    flex: "0 0 100%",
    height: linkButtonHeightMin,
    order: 2,
    "& > :first-child": {
      width: "73px",
    },
  },
  "& > :first-child ": {
    width: "73px,"
  },
  ".rectangle-active": {
    background: colors.white,
    width: "100%",
    height: "4px",
    gap: gaps.zero,
    borderRadius: `${borderRadius.small} ${borderRadius.small} ${borderRadius.zero} ${borderRadius.zero}`,
    marginBottom: `-${margin.small_5} !important`,
    marginTop: `${margin.xsmall1} !important`,
  },
  '@media (max-width:1279px)': {
    ".rectangle-active": {
      marginBottom: `-${margin.large1} !important`,
      marginTop: `${margin.medium} !important`,
      }
  },
  ".rectangle-none": {
    display: "none"
  },
  'div.MuiBox-root:not(.active):not([title]) > a >div': {
    position: 'relative',
    top: gaps.xsmall1,
  },
}));

const StyledNavButton = styled(Box)((props) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  height: linkButtonHeightMin,
  justifyContent: "center",
  // minWidth: linkButtonHeightMin,
  padding: padding.zero,
  width: linkButtonHeightMin,

  "& a": {
    borderRadius: borderRadius.small,
    color: colors.white,
    fontSize: fontSize.base,
    fontWeight: fontWeight.normal1,
    lineHeight: 1,
    padding: padding.zero,
    textDecoration: "none",
    textTransform: "initial",
    "&:hover": {
      color: colors.blue12,
      textDecoration: "none",
    },
    "&.disabledLink": {
      color: colors.blue12,
      cursor: "default",
      lineHeight: 1,
      "& .hover-content": {
        display: "none",
      },
      "&:hover": {
        color: colors.blue12,
      },
    },
    "&.disabledLink:hover ": {
      cursor: "default",
    },
    "&.disabledLink:hover .normal-content": {
      display: "none",
    },
    "&.disabledLink:hover .hover-content": {
      display: "flex",
    },
    "& div.MuiBox-root > div.MuiBox-root:not([title])": {
      background: 'black !important',
    },
  },
  "& a .icon": {
    color: colors.white,
    fontSize: fontSize.xlarge2,
    "&:hover": {
      color: colors.blue12,
      textDecoration: "none",
    },
  },
  "& .disabledLink .icon": {
    color: colors.blue12,
    fontSize: fontSize.xlarge2,
  },
  "& a div": {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: gaps.xsmall2,
    justifyContent: "center",
    padding: padding.xsmall,
  },
  "&:hover": {
    color: colors.blue12,
  },
  "&.disabled": {
    cursor: "default",
    opacity: 1,
  },
  "& div.MuiToolbar-root div.MuiBox-root": {
    borderBottom: `4px solid ${colors.white}`,
  },
  [theme.breakpoints.up("lg")]: {
    width: linkButtonHeightMax,
  },
  "& > :first-child ": {
    width: "73px,"
  },
}));

const ProfileSaveContainer = styled(Box)({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: gaps.small3,
  justifyContent: "end",
});

const StyledDivider = styled("div")({
  backgroundColor: colors.white,
  height: "39px",
  width: "2px",
});

const StyledContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
  backgroundColor: colors.grey01,
  borderRadius: borderRadius.small,
  color: colors.blue06,
  fontSize: fontSize.large,
  fontWeight: fontWeight.bold1,
  padding: padding.small2,
  paddingBottom: "0px",
});

const renderMenuItem = (
  { menuId, menuName, url, disabled, icon, headerIcon }: MenuDefinition,
  onNavigate: Function,
  showButtonLabels: boolean,
  active: boolean,
) => {
  const Icon = headerIcon ?? icon;
  const className = clsx(active ? "active" : "", disabled ? "disabled" : "");

  return (
    <StyledNavButton
      key={menuId}
      onClick={() => !disabled && onNavigate(url)}
      className={className}
      title={menuName}
    >
      {disabled ? (
        <Link
          data-menu-id={menuId}
          onClick={(event) => event.preventDefault()}
          to="/"
          className="disabledLink"
        >
          <Box className="normal-content">
            {Icon && <Icon className="icon" />}
            {showButtonLabels && menuName}
          </Box>
          <Box className="hover-content">
            {Icon && <Icon className="icon" />}
            {showButtonLabels && menuName}
          </Box>
        </Link>
      ) : (
        <Link
          data-menu-id={menuId}
          to={url}
          onClick={(event) => event.preventDefault()}
          className={active ? "active" : ""}
        >
          <Box>
            {Icon && <Icon className="icon" />}
            {showButtonLabels && menuName}
          </Box>
          <Box className={active ? "rectangle-active" : "rectangle-none"}></Box>
        </Link>
      )}
    </StyledNavButton>
  );
};

const TopNavBar = ({ onNavigate }: TopNavBarProps) => {
  const { featureConfig: { enableReportPay } } = useConfigContext();
  const [{ profileSettings },] =
    useProfileSettingsCache();
  const showButtonLabels = useMediaQuery(theme.breakpoints.up("lg"));

  const permissionCodes = (profileSettings?.userPermissionItemTypes as string[]);

  const filteredItems = menuDefinitions.filter(
    (menu) => menu.type === menuType.mainMenu && menu.isVisibleInHeader && permissionCodes?.includes(menu.permissionCode!),
  );

  const renderedItems = filteredItems.map((item) => {
    if (item.menuName === menuNames.lblMenuPayments) {
      item.disabled = !enableReportPay;
      if (enableReportPay) {
        return <PaymentsHeaderDropDown onNavigate={onNavigate} />;
      }
    }
    // if (item.type === menuNames.lblMenuReports) {
    //   item.disabled = profileSettings.userPermissionItemTypes?.includes(PERMISSIONS.VIEW_REPORTS) ? false : true;
    // }
    const active = window.location.pathname === item.url;
    return renderMenuItem(
      item,
      onNavigate,
      showButtonLabels,
      active,
    );
  });
  return <NavItemsContainer id="navBar">{renderedItems}</NavItemsContainer>;
};

const SaveFileNavIcon = ({ saveFileRef }: any) => {
  const { pathname } = useLocation();
  const [{ isSaving, isIssuedProductDirty, isReadOnly  }] = useFiles();
  const [{ isFilePolicyUploadEditMode } ] = useFilePolicyUploadStates();  
  const disableBtn = isSaving || isIssuedProductDirty || isReadOnly || isFilePolicyUploadEditMode;
  const showButtonLabel = useMediaQuery(theme.breakpoints.up("lg"));
  const StyledButton = styled(Button)({
    background: "transparent",
    height: fontSize.xlarge2,
    maxWidth: "64px",
    minWidth: iconSize.small1,
    padding: padding.zero,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      color: colors.blue12,
    },
    [theme.breakpoints.down("lg")]: {
      minWidth: fontSize.xlarge2,
      width: fontSize.xlarge2,
    },
    display: "flex",
    flexDirection: "row",
    fontSize: fontSize.base,
    fontWeight: fontWeight.normal1,
    gap: gaps.xsmall2,
    "& a": {
      color: colors.white,
      fontSize: fontSize.base,
      fontWeight: fontWeight.normal1,
      lineHeight: 1,
      textDecoration: "none",
      textTransform: "initial",
      "&:hover": {
        color: colors.white,
        textDecoration: "none",
      },
      "& .icon": {
        color: colors.white,
        fontSize: iconSize.medium,
        "&:hover": {
          color: colors.blue12,
        },
      },
    },
  });

  const handleClick = () => {
    if (!disableBtn && saveFileRef.current) {
      saveFileRef.current.click();
    }
  };

  if (isReadOnly || (!isCreateFilePage(pathname) && !isProfileSettingsPage(pathname)) ) return null;

  return (
    <StyledButton disabled={disableBtn} id="saveFile" onClick={handleClick}>
      <Link
        onClick={(event) => event.preventDefault()}
        to="/"
      >
        <SaveIcon className="icon" />
        {showButtonLabel && "Save"}
      </Link>
    </StyledButton>
  );
};

const Nav = ({ isValidLogin, saveFileRef, saveFileChangeRef, isDisabled }: NavProps) => {
  const { redirect } = useNavRedirect();
  const { pathname } = useLocation();
  const [{ showChangeAgency, showFileNumberBox, siteError }, { getSelectedAgency, getFileNumber, }] = useGlobalAccess();
  const selectedAgency = getSelectedAgency();
  const location = useLocation();
  const fileID = location?.state?.clientId as string ?? undefined;

  const handleNavigate = (url: string) => {
    redirect(url, saveFileChangeRef);
  };

  const showGAAgency = (!(
    isProfileSettingsPage(pathname) ||
    isHelpCenterPage(pathname))) &&
    showChangeAgency;
  const renderTopNav = !!isValidLogin && !isHomePage(pathname);

  const showFileNumber = (isCreateFilePage(pathname));
  const getDisplayFileNumber = () => {
    return fileID ? fileID : getFileNumber();
  };

  const FileNameTypography = styled(Typography)({
    fontSize: fontSize.medium,
    fontWeight: fontWeight.bold2,
    letterSpacing: letterSpacing.medium2,
    marginRight: margin.xsmall1,
  });

  const FileNumberTypography = styled(Typography)({
    fontSize: fontSize.medium,
    fontWeight: fontWeight.normal1,
    letterSpacing: letterSpacing.medium2,
  });

  // MyAppsDropdown logic
  const [{ profileSettings }] = useProfileSettingsCache();
  const permissionCodes = profileSettings?.userPermissionItemTypes as string[];
  const hasPolicyAccessPermission = permissionCodes?.includes(PERMISSIONS.POLICY_ACCESS);
  // not needed inside SCMain const hasSiteAcessPermission = permissionCodes?.includes(PERMISSIONS.SITE_ACCESS);
  const hasAdminAccessPermission = permissionCodes?.includes(PERMISSIONS.ADMIN_ACCESS);
  const [{isReadOnlyMode}, { setIsReadOnly }] = useFiles();

  const hasFileViewAccess = permissionCodes?.includes(PERMISSIONS.FILES_VIEW) && !permissionCodes?.includes(PERMISSIONS.FILES_MODIFY) ; 
  
  let enableMyAppsLink = false;
  if (hasPolicyAccessPermission || hasAdminAccessPermission)
      enableMyAppsLink = true;

  useEffect(() => {
    if (!isProfileSettingsPage(pathname) && !isReadOnlyMode) {
      setIsReadOnly(hasFileViewAccess);
    }
  }, [permissionCodes, pathname,]);

      return (
        <CustomAppBar>
          <StyledToolbar>
            {/*wrap the logo and the MyApps popup in a box (like 'ProfileSaveContainer') so they stay together to the left */}
            <ProfileSaveContainer>
              <StyledLogo
                id="logo"
                data-menu-id={menuIds.menuHome}
                aria-label="Stewart Title"
                title="Stewart Title"
                onClick={() => handleNavigate(getUrlByMenuId(menuIds.menuHome))} // "/Home")}
              />
              {enableMyAppsLink && (
                <MyAppsLink
                  isValidLogin={isValidLogin}
                  saveFileChangeRef={saveFileChangeRef}
                />
              )}
            </ProfileSaveContainer>

            {/*middle section of the toolbar */}
            <AuthenticatedTemplate>
              {renderTopNav && (
                <TopNavBar
                  onNavigate={handleNavigate}
                  {...{ saveFileChangeRef }}
                />
              )}
            </AuthenticatedTemplate>

            {/*right section of the toolbar */}
            <AuthenticatedTemplate>
              <ProfileSaveContainer>
                {renderTopNav && (
                  <>
                    <SaveFileNavIcon {...{ saveFileRef }} />
                    <StyledDivider />
                  </>
                )}
                <EditProfileLink
                  isValidLogin={isValidLogin}
                  saveFileChangeRef={saveFileChangeRef}
                />
              </ProfileSaveContainer>
            </AuthenticatedTemplate>
          </StyledToolbar>
          <MessageBanner onNavigate={handleNavigate} />
          <StyledContainer>
            {showFileNumber && showFileNumberBox && (
              <>
                {location.pathname === "/file" ? (
                  <div style={{ display: "flex" }}>
                    <FileNameTypography>File Name/Number:</FileNameTypography>
                    <FileNumberTypography>{getDisplayFileNumber()}</FileNumberTypography>
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            )}
            <div style={{ marginLeft: "auto" }}>
              {showGAAgency && !siteError ? (
                <GlobalAccessAgency
                  isDisabled={isDisabled}
                  selectedAgencyName={selectedAgency?.length > 0 ? selectedAgency[0].CompanyName : ""}
                  saveFileChangeRef={saveFileChangeRef}
                  selectedAgencyStatus={selectedAgency?.length > 0 ? selectedAgency[0].Status : ""}
                />
              ) : null}
            </div>
          </StyledContainer>
        </CustomAppBar>
      );
};

const Header = ({
  isValidLogin,
  saveFileRef,
  saveFileChangeRef,
  isDisabled,
}: HeaderProps) => {

  return <Nav {...{ isValidLogin, saveFileRef, saveFileChangeRef, isDisabled }} />;
};

export default Header;


  
