import { useNavigate } from 'react-router';
import { styled } from "@mui/material";
import { Replay } from "@mui/icons-material";
import ActionButton from "controls/global/action-button";
import CircularProgressWithLabel from "controls/global/circular-progress-with-label";
import React, {
  useEffect,
  useState,
} from "react";
import {
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  gaps,
  letterSpacing,
  zIndex,
} from "theme/defaultStyle";
import { SiteErrorType } from "utils/data/enum";
import { useGlobalAccess } from 'utils/context/GlobalAccessContext';
import { useConfigContext } from "utils/context/ConfigContextProvider";
import { useMsal } from '@azure/msal-react';

const PAGE_NOT_FOUND_MESSAGE = "Page Not Found";
const LOGIN_MESSAGE = "One moment while we access your Stewart Connect user account.";
const PASSWORD_EXPIRED_TITLE = "Password Error";
const PASSWORD_EXPIRED_MESSAGE =
  "Your password must be reset. Please click ‘Back to Login’ below to return to the login page, and select the Forgot Password link.";
const BAD_ACCOUNT_MESSAGE = "There is a problem with your account.";
const NETWORK_ISSUE_MESSAGE = "A network error may have occurred.";
const UNHANDLED_EXCEPTION_MESSAGE = "An unexpected error has occurred.";
// const CONTACT_MESSAGE = "Please contact the Agency Support Center for assistance.";
const CONTACT_MESSAGE = "If you need further assistance, please contact the Agency Support Center.";
const TRY_AGAIN_CONTACT_MESSAGE = "Please try again or contact the Agency Support Center for assistance.";
const NO_MODULE_ACCESS_MESSAGE = "You do not have access to any modules";
const PERMISSIONS_CHANGED_MESSAGE = "Your permissions have changed. Please select an icon in the navigation bar to continue.";

interface Props {
  errorType?: SiteErrorType;
  progressPercent?: number;
  errorMessage?: string;
}

const MessageContainer = styled("div")(({ center }: { center: boolean }) => ({
  alignItems: "center",
  color: colors.grey08,
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  fontFamily: fontFamily.inter, // "Roboto",
  fontSize: fontSize.xlarge,
  fontStyle: "normal",
  fontWeight: fontWeight.normal1,
  gap: gaps.large1,
  height: "100%",
  justifyContent: center ? "center" : "flex-start",
  letterSpacing: letterSpacing.medium1,
  lineHeight: "normal", // "160%",
  textAlign: "center",
  "& .agency": {
    fontWeight: fontWeight.bold2,
  },
  "& .contact": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& .email a": {
      color: colors.blue01,
      fontWeight: fontWeight.normal1,
      textDecoration: "underline",
    },
    "& .phone a": {
      color: colors.grey08,
      textDecorationLine: "none",
    },
  },
  "& .title": {
    fontSize: fontSize.xlarge2a,
    fontWeight: fontWeight.bold2,
    background: colors.redGradient,
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  "& .message": {
    maxWidth: "750px",
  },
  "& .messageBackToLogin": {
    maxWidth: "720px",
  }
}));

const CircularProgressContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  left: "50%",
  position: "fixed",
  top: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  zIndex: zIndex.positiveMax,
  "& .MuiCircularProgress-circleDeterminate": {
    transition: "stroke-dashoffset 0ms",
  },
});

export const SiteErrorMessage = ({
  errorType = undefined,
  progressPercent = undefined,
  errorMessage = undefined,
}: Props) => {
  const [, { setSiteError }] = useGlobalAccess();
  const [titleMessage, setTitleMessage] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [contactMessage, setContactMessage] = useState<string>("");
  const [showReloadLink, setShowReloadLink] = useState<boolean>();
  const [showBackToLoginLink, setShowBackToLoginLink] = useState<boolean>(false);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { generalConfig } = useConfigContext();

  useEffect(() => {
    let title = "";
    let msg = "";
    let contactMsg = "";
    let showReload = false;
    let showBackToLogin = false;

    switch (errorType) {
      case SiteErrorType.BadAccount:
        msg = BAD_ACCOUNT_MESSAGE;
        contactMsg = CONTACT_MESSAGE;
        break;

      case SiteErrorType.PasswordExpired:
        title = PASSWORD_EXPIRED_TITLE;
        msg = PASSWORD_EXPIRED_MESSAGE;
        contactMsg = CONTACT_MESSAGE;
        showBackToLogin = true;
        break;

      case SiteErrorType.NetworkError:
        msg = NETWORK_ISSUE_MESSAGE;
        contactMsg = TRY_AGAIN_CONTACT_MESSAGE;
        showReload = true;
        break;

      case SiteErrorType.NotFound:
        msg = PAGE_NOT_FOUND_MESSAGE;
        contactMsg = TRY_AGAIN_CONTACT_MESSAGE;
        break;

      case SiteErrorType.Exception:
        msg = UNHANDLED_EXCEPTION_MESSAGE;
        contactMsg = TRY_AGAIN_CONTACT_MESSAGE;
        break;

      case SiteErrorType.NoModuleAccess:
        msg = NO_MODULE_ACCESS_MESSAGE;
        contactMsg = CONTACT_MESSAGE;
        break;

      case SiteErrorType.ErrorBoundary:
        msg = UNHANDLED_EXCEPTION_MESSAGE;
        contactMsg = TRY_AGAIN_CONTACT_MESSAGE;
        showReload = true;
        break;
      
      case SiteErrorType.NoAccess:
        msg = PERMISSIONS_CHANGED_MESSAGE;
        contactMsg = CONTACT_MESSAGE;
        break;

      default:
        msg = LOGIN_MESSAGE;
        contactMsg = "";
        break;
    }

    setTitleMessage(title);
    setMessage(errorMessage || msg);
    setContactMessage(contactMsg);
    setShowReloadLink(showReload);
    setShowBackToLoginLink(showBackToLogin);
  }, [errorType]);

  useEffect(() => {
    setSiteError(true);
    return () => {
      setSiteError(false);
    };
  }, []);

  return (
    <>
      {progressPercent !== undefined && (
        <CircularProgressContainer>
          <CircularProgressWithLabel value={progressPercent} />
        </CircularProgressContainer>
      )}
      <MessageContainer id="login-message" center={!!contactMessage}>
        {titleMessage && <div className="title">{titleMessage}</div>}
        <div
          className={
            errorType === SiteErrorType.PasswordExpired
              ? "messageBackToLogin"
              : "message"
          }
        >
          <div>{message} {contactMessage && (contactMessage)}</div>
          {/* {contactMessage && <div>{contactMessage}</div>} */}
        </div>

        {contactMessage && (
          <div className="contact">
            {/* <div className="agency">Agency Support Center</div> */}
            <div className="phone">
              Phone:&nbsp; 
              <a href={`tel:${generalConfig.supportPhone}`}>
                {generalConfig.supportPhone}
              </a>
            </div>
            <div className="email">
              Email:&nbsp;
              <a href={`mailto:${generalConfig.supportEmail}`}>
                {generalConfig.supportEmail}
              </a>
            </div>
          </div>
        )}

        {showReloadLink && (
          <div className="reload">
            <ActionButton
              variant="outlined"
              color="primary"
              startIcon={<Replay />}
              onClick={() => navigate(0)}
            >
              Reload
            </ActionButton>
          </div>
        )}
        {showBackToLoginLink && (
          <div className="reload">
            <ActionButton
              variant="outlined"
              color="primary"
              onClick={() => instance.logout()}
            >
              Back To Login
            </ActionButton>
          </div>
        )}
      </MessageContainer>
    </>
  );
};
