import { AxiosRequestConfig } from "axios";
import { axiosSecuredInstance } from "configurations/axiosConfig";
import { SignatureConfigurationNode } from "entities/ApiModel/SignatureConfigurationNode";
import { SignatureImage } from "entities/ApiModel/SignatureImage";
import { Signature } from "entities/UIModel/Signature";
import { orderBy } from "lodash";
import {
  createHook,
  createStore,
  StoreActionApi,
} from "react-sweet-state";

type State = {
  error: any;
  isLoading: boolean;
  isSaving: boolean;
  signatures?: Signature[];
  cachedSignature: Signature | null;
  signatureModified: number;
};
type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

const setError =
  (error: string | null) =>
    ({ setState }: StoreApi) => {
      setState({ error });
    };

const getData =
  (
    url: string,
    action: (param: Array<any>) => ({ setState }: StoreApi) => any,
    config?: AxiosRequestConfig
  ) =>
    async ({ dispatch }: StoreApi) => {
      try {
        const { data } = await axiosSecuredInstance.get(url, config);
        dispatch(action(data));
        return data;
      } catch (error: any) {
        dispatch(setError(error));
      }
    };

const setSignatures =
  (data: Array<SignatureImage>) =>
    ({ setState }: StoreApi) => {
      setState({
        signatures: data.map((signature) => mapToSignature(signature)),
        isLoading: false,
        error: null,
        cachedSignature: null,
      });
    };

const setSignature =
  (signatureImage: SignatureImage) =>
    ({ setState }: StoreApi) => {
      const cachedSignature = mapToSignature(signatureImage);
      setState({
        cachedSignature: cachedSignature,
        isLoading: false,
      });

      return cachedSignature;
    };

const removeFromSignatures =
  (signatureId: number) =>
    ({ getState, setState }: StoreApi) => {
      const signatures = getState().signatures;
      if (signatures !== undefined) {
        setState({
          signatures: signatures.filter(s =>
            s.signatureImageID !== signatureId)
        });
      }
    };

const updateSignatures =
  (signature: Signature) =>
    ({ getState, setState }: StoreApi) => {
      const signatures = getState().signatures;
      if (signatures !== undefined) {
        const id = signature.signatureImageID;
        const updated = signatures.map(s => s.signatureImageID === id ? signature : s);
        setState({
          signatures: orderBy(updated, "signatureImageName")
        });
      }
    };

const mapToSignature = (signature: SignatureImage): Signature => ({
  signatureImageID: signature.SignatureImageID,
  signatureImageName: signature.SignatureImageName,
  signatureImageDescription: signature.SignatureImageDescription,
  signatureImageSourceTypeCode: signature.SignatureImageSourceTypeCode,
  signatureImageFontTypeCode: signature.SignatureImageFontTypeCode,
  signatureImageFormatTypeCode: signature.SignatureImageFormatTypeCode,
  signatureImageData: toBase64(signature.SignatureImageData),
  signatureLevelTypeCode: signature.SignatureLevelTypeCode,
  signatureConfiguration: signature.SignatureConfiguration,
  integrationKey: signature.IntegrationKey,
  isDefaulted: signature.IsDefaulted,
  isDefaultedByOther: signature.IsDefaultedByOther || 0,
});

const toBase64 = (data: string): string => {
  return data.startsWith("data:image/")
    ? data
    : `data:image/jpeg;base64,${data}`;
};

const mapToSignatureImage = (signature: Signature): SignatureImage => ({
  SignatureImageID: signature.signatureImageID,
  SignatureImageName: signature.signatureImageName,
  SignatureImageDescription: signature.signatureImageDescription,
  SignatureImageSourceTypeCode: signature.signatureImageSourceTypeCode,
  SignatureImageFormatTypeCode: signature.signatureImageFormatTypeCode,
  SignatureImageFontTypeCode: signature.signatureImageFontTypeCode,
  IsOffline: 0,
  SignatureImagePath: "",
  EnteredByUserID: "00000000-0000-0000-0000-000000000000",
  SignatureConfiguration: signature.signatureConfiguration,
  SignatureImageFileName: "",
  SignatureImageData: signature.signatureImageData,
  SignatureLevelTypeCode: signature.signatureLevelTypeCode,
  IntegrationKey: signature.integrationKey,
  IsDefaulted: signature.isDefaulted,
});

const initialState = {
  error: null,
  isLoading: false,
  isSaving: false,
  cachedSignature: null,
  signatureModified: 0,
};

const actions = {
  checkName: (proposedName: string) => async () => {
    const propName = encodeURIComponent(proposedName);
    const url = "/Signature/v2/checkName?proposedName=" + propName;
    const { data } = await axiosSecuredInstance.get<number>(url);
    return data;
  },
  getSignatures:
    (agencyId : string = "") =>
      async ({ dispatch, setState }: StoreApi) => {
        setState({ isLoading: true });
        dispatch(getData(`/Signature/v2/getSignatures?AgencyId=${agencyId}`, setSignatures));
      },
  getSignature:
    (signatureImageID: number) =>
      async ({ dispatch, setState }: StoreApi) => {
        setState({ isLoading: true });
        const { data } = await axiosSecuredInstance.get<SignatureImage>(
          `/Signature/v2/${signatureImageID}`
        );

        const currentSignature = dispatch(setSignature(data));
        return currentSignature;
      },
  getSignatureConfiguration: (agencyId : string = "") => async () => {
    const { data } = await axiosSecuredInstance.get<SignatureConfigurationNode>(
      `/Signature/v2/getSignatureConfiguration?AgencyId=${agencyId}`
    );
    return data;
  },
  saveSignature:
    (signature: Signature) =>
      async ({ dispatch, getState, setState }: StoreApi) => {
        try {
          setState({ isSaving: true });
          const mappedData = mapToSignatureImage(signature);
          const { data, status } = await axiosSecuredInstance.post("/Signature/v2/saveSignature", mappedData);
          if (status === 200) {
            const updatedSignature = mapToSignature(data);
            dispatch(updateSignatures(updatedSignature));
            setState({ signatureModified: Number(getState().signatureModified) + 1 });
          }
          setState({ isSaving: false });
        } catch (error) {
          console.error(error);
          setState({ error, isSaving: false });
        }
      },
  deleteSignature:
    (signatureId: number) =>
      async ({ dispatch, getState, setState }: StoreApi) => {
        try {
          const response = await axiosSecuredInstance.delete(`/Signature/v2/${signatureId}`);
          if (response.status === 200) {
            dispatch(removeFromSignatures(signatureId));
            setState({ signatureModified: getState().signatureModified + 1 });
          }
        } catch (error) {
          console.error(error);
          setState({ error });
        }
      },
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
  name: "signatureContext",
});

const hook = createHook(Store);
export const useSignatures = () => { return hook() };
