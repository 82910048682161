import { Typography } from "@mui/material";
import { SignatureConfigurationNode } from "entities/ApiModel/SignatureConfigurationNode";
import { cloneDeep } from "lodash";
import { useConfigurationProvider } from "pages/signatures/providers/manage-signature/ConfigurationProvider";
import React, { useEffect, useState } from "react";
import { colors } from "theme/defaultStyle";
import Body from "../signature-dialog/SignatureDialogBody";
import RecursiveTreeView from "../tree-view";
import { useGlobalAccess } from "utils/context/GlobalAccessContext";

export default function SignatureConfiguration() {
  const {
    state: { nodes },
    dispatch,
  } = useConfigurationProvider();

  const [{ selectedAgency: globalAccessAgency }] = useGlobalAccess();
  const [objectNode, setObjectNode] = useState<SignatureConfigurationNode | null>(null);

  useEffect(() => {
    if (nodes?.Children) {
      const filteredObject = nodes.Children.find(
        (item: SignatureConfigurationNode) => item.ID === globalAccessAgency[0]?.CompanyID,
      );
      setObjectNode(filteredObject || nodes);
    }
  }, [globalAccessAgency, nodes]);

  const onDataChanged = (data: SignatureConfigurationNode) => {
    dispatch({ type: "DATA_CHANGED", data: cloneDeep(data) });
  };

  return (
    <Body
      title="Where would you like this signature to be available?"
      width={920}
    >
      {nodes ? (
        <RecursiveTreeView
          data={objectNode || nodes}
          onDataChanged={onDataChanged}
        />
      ) : (
        <Typography style={{ fontSize: 16, color: colors.grey07 }}>
          {nodes === null ? "No configuration available." : "Loading..."}
        </Typography>
      )}
    </Body>
  );
}
